.App {
  text-align: center;
  max-width: 1000px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.45rem;
}

.App-link {
  color: #61dafb;
}

.signout-container {
  display: flex;
  justify-content: center;
  margin: 1.45rem;
}

.options-container input, button{
  margin: auto;
}

.options-container > label, button {
  display: block;
  margin-top: 0.5em;
}

.update-button {
  background-color: var(--button-secondary-color);
  padding: 0.75em;
  border-radius: 0.2;
}